import i18n from 'i18next'
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import {isNull} from "./izUtils";

let lang = 'sl-SI';
const new_lang = localStorage.getItem('SMM-360.selectedLanguage');
if (!isNull(new_lang)) {
    lang = new_lang;
}

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
        lng: lang,
        backend: {
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'sl-SI',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n
