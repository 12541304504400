export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const isNull = (value) => {
   if (value === undefined || value === null) {
        return true;
   }

   return false;
};

export const dateFormatBE = (date) => {
    const dateTime = date.split('T');
    return  dateTime[0] + ' ' + dateTime[1].replace('Z','');
}

export const displayDate = (date, type, withSeconds) => {
    const newDate = new Date(date);

    const year = newDate.getFullYear()+'';
    let month = newDate.getMonth() + 1+'';
    let day = newDate.getDate()+'';
    let hours = newDate.getHours()+'';
    let minutes = newDate.getMinutes()+'';
    let seconds = newDate.getSeconds()+''

    if (month.length === 1) month = '0' + month;
    if (day.length === 1) day = '0' + day;
    if (hours.length === 1) hours = '0' + hours;
    if (minutes.length === 1) minutes = '0' + minutes;
    if (seconds.length === 1) seconds = '0' + seconds;


    if (type === 'date') {
        return day + "." + month + "." + year;
    } else if (type === 'time') {
        return hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    } else {
        return day + "." + month + "." + year + " " + hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    }
}

export const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '30px',
      transform: 'translate(-50%, -50%)',
      width: '450px',
      maxWidth: '95%',
      maxHeight: '90vh',
      borderRadius: '12px',
      boxShadow: '0px 0px 34px rgba(0, 0, 0, 0.28)',
    },
};