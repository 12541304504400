import { createSlice } from '@reduxjs/toolkit';
import { parseJwt } from '../../izUtils'; 

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: null,
        userData: null,
        options: {
            postHeaders: {
                headers: {
                    "Accept": 'application/json',
                    "Accept-Language": "sl-SI",
                }
            }
        }
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        setUserData: (state, action) => {
            state.userData = action.payload
        },
        setJwtToken: (state, action) => {
            if (action.payload !== null) {
                state.options.postHeaders.headers['Authorization'] = action.payload;
                const parsedToken =  parseJwt(action.payload);
                document.body.classList.add(parsedToken.type);
                state.options.parsedToken = parsedToken;
            } else {
                delete state.options.postHeaders.headers.Authorization;
                delete state.options.parsedToken;
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { setToken, setUserData, setJwtToken } = userSlice.actions

export default userSlice.reducer