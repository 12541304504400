import React, {Fragment, useEffect, Suspense, useRef} from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import axios from "axios";
import Swal from 'sweetalert2';

import { setToken, setUserData, setJwtToken } from './components/slices/userSlice';
import { setBrands, setBrand, setProfile } from './components/slices/brandSlice';
import { setNewMessage, setSocketId } from './components/slices/chatSlice';
import env from './env/src_config';
import { isNull } from './izUtils';
import { axiosError } from './hoc/helpers/axiosError';
import { parseJwt } from './izUtils';

import './assets/css/main.css';

import Loading from './hoc/loading/Loading';
const Header = React.lazy(() => import('./hoc/header/Header'));
const Login = React.lazy(() => import('./components/unSigned/Login'));
const Register = React.lazy(() => import('./components/unSigned/Register'));
const ForgotPassword = React.lazy(() => import('./components/unSigned/ForgotPassword'));
const PasswordReset = React.lazy(() => import('./components/unSigned/PasswordReset'));
const Verify = React.lazy(() => import('./components/unSigned/Verify'));

// Company
const CompanyCreate = React.lazy(() => import('./components/signed/company/Company'));
const Company = React.lazy(() => import('./components/signed/company/myCompany/Company'));

// Brands
const Brands = React.lazy(() => import('./components/signed/brand/Brands'));
const Brand = React.lazy(() => import('./components/signed/brand/Brand'));
const Calendar = React.lazy(() => import('./components/signed/calendar/Calendar'));
const SocialPages = React.lazy(() => import('./components/signed/brand/SocialPages'));

// Campaign
const SingleCampaign = React.lazy(() => import('./components/signed/campaign/SingleCampaign'));
const AllCampaigns = React.lazy(() => import('./components/signed/campaign/AllCampaigns'));
const CreateCampaign = React.lazy(() => import('./components/signed/campaign/CreateCampaign'));
const DatabaseCreators = React.lazy(() => import('./components/signed/campaign/creator/DatabaseCreators'));
const CampaignInvitation = React.lazy(() => import('./components/signed/campaign/CampaignInvitation'));

// Creator
const Offers = React.lazy(() => import('./components/signed/campaign/creator/Offers'));
const Collaborations = React.lazy(() => import('./components/signed/campaign/creator/Collaborations'));
const Settings = React.lazy(() => import('./components/signed/campaign/creator/Settings'));
const Profile = React.lazy(() => import('./components/signed/user/Profile'));

function App() {
    const state = useSelector((state) => state)
    const dispatch = useDispatch()
    const cookies = new Cookies();
    const ws = useRef(null);
    const { t } = useTranslation();

    // Run only on first render
    useEffect(() => {
        // Set token and user data
        const cookie = (cookies.get('token'));
        let userData = null;
        if (!isNull(cookie)) {
            const parsedToken = parseJwt(cookie);
            if (parsedToken.exp * 1000 < Date.now()) {
                Swal.fire({
                    title: t('logout.title'),
                    html: t('logout.html'),
                    icon: 'error',
                    confirmButtonColor: '#F6790B',
                    confirmButtonText: t('ok')
                });
                signOut();
            } else {
                dispatch(setJwtToken('Bearer ' + cookie));
                dispatch(setToken(cookie));
                
                userData = JSON.parse(localStorage.getItem('SMM-360.userData'));
                if (!isNull(userData)) dispatch(setUserData(JSON.parse(localStorage.getItem('SMM-360.userData'))));
            }
        } else {
            dispatch(setJwtToken(null));
            dispatch(setToken(''));
        }

        // Set brands and/or campaign
        const brands = JSON.parse(localStorage.getItem('SMM-360.brands'));
        if (brands !== undefined) dispatch(setBrands(brands));
        const selectedBrand = JSON.parse(localStorage.getItem('SMM-360.selectedBrand'));
        const selectedProfile = JSON.parse(localStorage.getItem('SMM-360.selectedProfile'));
        
        if (!isNull(selectedBrand)) {
            dispatch(setBrand(selectedBrand));
        } else if (!isNull(selectedProfile)) {
            dispatch(setProfile(selectedProfile));
            document.body.classList.add(selectedProfile);
        }

        // eslint-disable-next-line
    }, []);

    // Init WS
    useEffect(() => {
        if ((!isNull(state.user.token)) && (state.user.token.length !== 0) && (ws.current === null)) {
            ws.current = new WebSocket(env.wss+state.user.token);
            ws.current.onopen = (event) => {
                console.log("onopen")
                console.log(event)
            };

            ws.current.onmessage = function (event) {
                const json = JSON.parse(event.data);
                console.log("onmessage")
                console.log(json)
                
                try {
                    if (json.event === "pusher:connection_established") {
                        const data = JSON.parse(json.data);
                        dispatch(setSocketId(data.socket_id));
                    }

                    // New message
                    if (json.event === "chat.message") {
                        dispatch(setNewMessage(json));
                    }
                } catch (err) {
                    console.log(err);
                }
            };
        }

        return () => { 
            if (ws.current !== null) {
                ws.current.close()
                ws.current = null;
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user.token])

    // Subscribe to WS channels
    useEffect(() => {
        if (!isNull(ws.current) && !isNull(state.chat.subscribeTo)) {
            state.chat.subscribeTo.forEach(channel => {
                ws.current.send(JSON.stringify({
                    event: "pusher:subscribe",
                    data: {
                    auth: channel.auth,
                    channel: channel.channel
                    }
                }));
            })
        }
    }, [state.chat.subscribeTo])

    const signIn = (token) => {
        cookies.set('token', token, { path: '/' });
        getUserData(token);
    }

    const signOut = () => {
        // Delete user data
        cookies.remove('token');
        localStorage.removeItem('SMM-360.userData');
        dispatch(setToken(''));
        dispatch(setUserData(null));
        dispatch(setJwtToken(null));
        
        // Delete brands info
        localStorage.removeItem('SMM-360.selectedBrand');
        dispatch(setBrand(null));
        localStorage.removeItem('SMM-360.brands');
        dispatch(setBrands([]));

        // Delete campaign info
        localStorage.removeItem('SMM-360.selectedProfile');
        dispatch(setProfile(null));

        // Remove all classes from body
        document.body.className = '';
    }

    const getUserData = (passedToken) => {
        const headers = {
            "Accept": 'application/json',
            "Accept-Language": "sl-SI",
            "Authorization": "Bearer " + passedToken
        }

        axios.post(env.api + '/api/user/data', {}, {headers}).then(function (response) {
            localStorage.setItem('SMM-360.userData', JSON.stringify(response.data));
            dispatch(setUserData(response.data));
            dispatch(setJwtToken('Bearer ' + passedToken));
            dispatch(setToken(passedToken));
        }).catch(function (error) {
            axiosError(error);
        });
    }

    if (state.user.token === null) {
        return <Loading />
    } else {
        // console.log(state)
        return (
            <Fragment>
                {state.user.token !== '' ?
                    <Fragment>
                        <Suspense fallback={<></>}><Header signOut={signOut} /></Suspense>
                        <div className='container-fluid'>
                            <div className='main-content'>
                                <Routes>
                                    {!isNull(state.user.userData) && state.user.userData.user_type === 'user' ?
                                        <Fragment>
                                            <Route path="/" element={
                                                <Suspense fallback={<Loading />}>
                                                    {state.user.userData.company_data !== null ? <Brands /> : <CompanyCreate getUserData={getUserData} />}
                                                </Suspense>
                                            }/>
                                            <Route path="/brands" element={ <Suspense fallback={<Loading />}><Brands /></Suspense> } />
                                            <Route path="/brands/:brandId" element={ <Suspense fallback={<Loading />}><Brand /></Suspense> } />
                                            <Route path="/brands/:brandId/social-pages" element={ <Suspense fallback={<Loading />}><SocialPages /></Suspense> } />

                                            <Route path="/company" element={ <Suspense fallback={<Loading />}><Company /></Suspense> } />
                                            <Route path="/calendar/:brandId/:year/:month" element={ <Suspense fallback={<Loading />}><Calendar /></Suspense> } />
                                            <Route path="/profile" element={ <Suspense fallback={<Loading />}><Profile /></Suspense> } />

                                            <Route path="/campaigns/all" element={ <Suspense fallback={<Loading />}><AllCampaigns /></Suspense> } />
                                            <Route path="/campaigns/:campaignId" element={ <Suspense fallback={<Loading />}><SingleCampaign /></Suspense> } />
                                            <Route path="/campaigns/create/:campaignId" element={ <Suspense fallback={<Loading />}><CreateCampaign /></Suspense> } />
                                            <Route path="/campaigns/creators" element={ <Suspense fallback={<Loading />}><DatabaseCreators /></Suspense> } />
                                            <Route path="/campaigns/:campaignId/invitation" element={ <Suspense fallback={<Loading />}><CampaignInvitation /></Suspense> } />

                                            <Route path="*" element={<Navigate to="/" />} />
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <Route path="/campaigns/all" element={ <Suspense fallback={<Loading />}><AllCampaigns /></Suspense> } />
                                            <Route path="/campaigns/:campaignId" element={ <Suspense fallback={<Loading />}><SingleCampaign /></Suspense> } />
                                            <Route path="/offers" element={ <Suspense fallback={<Loading />}><Offers /></Suspense> } />
                                            <Route path="/collaborations" element={ <Suspense fallback={<Loading />}><Collaborations /></Suspense> } />
                                            <Route path="/settings" element={ <Suspense fallback={<Loading />}><Settings /></Suspense> } />
                                            <Route path="/profile" element={ <Suspense fallback={<Loading />}><Profile /></Suspense> } />

                                            <Route path="*" element={<Navigate to="/campaigns/all" />} />
                                        </Fragment>
                                    }
                                </Routes>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <div className='col-12 d-flex justify-content-center'>
                        <Routes>

                            <Route path="/login" element={ <Suspense fallback={<Loading />}><Login signIn={signIn} /></Suspense> } />
                            <Route path="/register" element={ <Suspense fallback={<Loading />}><Register /></Suspense> } />
                            <Route path="/forgot-password" element={ <Suspense fallback={<Loading />}><ForgotPassword /></Suspense> } />
                            <Route path="/password-reset/:token" element={ <Suspense fallback={<Loading />}><PasswordReset /></Suspense> } />
                            <Route path="/verify/:token" element={ <Suspense fallback={<Loading />}><Verify /></Suspense> } />
                            <Route path="/confirm/:token" element={ <Suspense fallback={<Loading />}><Verify /></Suspense> } />

                            <Route path="*" element={<Navigate to="/login" replace/>} />
                        </Routes>
                    </div>
                }
            </Fragment>
        );
    }

}

export default App;
