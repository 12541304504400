import { configureStore } from '@reduxjs/toolkit'
import brandReducer from '../src/components/slices/brandSlice'
import userReducer from '../src/components/slices/userSlice'
import chatReducer from '../src/components/slices/chatSlice'

export default configureStore({
  reducer: {
    brand: brandReducer,
    user: userReducer,
    chat: chatReducer,
  }
})