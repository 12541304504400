import { createSlice } from '@reduxjs/toolkit'

export const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    brands: [],
    brand: null,
    selectedProfile: null,
  },
  reducers: {
    setBrands: (state, action) => {
        state.brands = action.payload;
    },
    setBrand: (state, action) => {
      state.brand = action.payload;
    },
    setProfile: (state, action) => {
      state.selectedProfile = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setBrands, setBrand, setProfile } = brandSlice.actions

export default brandSlice.reducer