import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    newMessage: null,
    socketId: null,
    subscribeTo: null,
  },
  reducers: {
    setNewMessage: (state, action) => {
        const message = {
          channel: action.payload.channel,
          chatId: action.payload.channel.split('.')[1],
          ...JSON.parse(action.payload.data),
        }
        state.newMessage = message;
    },
    setSocketId: (state, action) => {
      state.socketId = action.payload;
    },
    subscribe: (state, action) => {
      state.subscribeTo = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setNewMessage, setSocketId, subscribe, initWS } = chatSlice.actions

export default chatSlice.reducer