import React from 'react';
import { Bars } from  'react-loader-spinner'

const Loading = () => {
  return (
    <div className='col-12 d-flex justify-content-center mt-5'>
        <Bars color="#F6790B" height={80} width={80} />
    </div>
  )
}

export default Loading